.loading-square {
  border-radius: 4px;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #f9fafb 25%, #f2f3f5 50%, #f9fafb 75%);
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s infinite linear;
}

@keyframes loadingAnimation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
